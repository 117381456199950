import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EnvService } from '../../sharedBase/env.service';
//import { EnvService } from '@ojas-enterprise/baseapp/dist/sharedBase';


@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true
    }
  ]
})
export class FileUploadComponent implements OnInit, ControlValueAccessor {
  @Input() public service: any;
  @Input() public accept = '';
  @Input() public multiple = '';
  @Input() public filesJson: string;
  @Input() public showImage = false;
  @Input() public editMode = false;
  @Input() public maxFileSize = 52428800;
  @Input() public minFileSize = 1000;
  @Input() public maxFileNumber = 100;
  @Input() public minFileNumber = 0;
  @Input() public controlDefault: FormControl = null;
  @Output() OnChange: EventEmitter<any> = new EventEmitter();

  uploadedFiles = [];
  uploadingFiles = [];
  disabled = false;
  value = '';
  basePath = '';
  showDefault = false;
  selectedValue = '';
  imageShowDialog: boolean = false;
  imageUrl: any;
  onModelChange: Function = () => { };
  onModelTouched: Function = () => { };
  constructor(private env: EnvService) {
    //console.log('env service for FileUpload', env.apiUrl);
  }

  ngOnInit() {
    this.showDefault = !(this.controlDefault == null);
    if (this.showDefault) {
      this.selectedValue = this.controlDefault.value;
    }
  }

  writeValue(value: string): void {
    this.value = value;
    if (this.value && this.value.length > 10) {
      this.uploadedFiles = JSON.parse(this.value);
    }
  }

  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  fileUpload(event) {
    //console.log('fileUpload file event.files: ', event)
    //this.onModelTouched();

    for (const file of event.files) {
      //console.log('fileUpload file: ', file)
      this.uploadingFiles.push(file);
      this.service.fileUpload(file).subscribe((fileInfo: IFileUpload) => {
        this.uploadedFiles.push(fileInfo);
        const index: number = this.uploadingFiles.indexOf(file);
        if (index !== -1) {
          this.uploadingFiles.splice(index, 1);
        }
        //console.log('this.uploadedFiles', this.uploadedFiles, JSON.stringify([...this.uploadedFiles]));
        this.value = JSON.stringify([...this.uploadedFiles]);
        this.onModelChange(this.value);
        this.OnChange.emit({
          originalEvent: event.originalEvent,
          value: [...this.uploadedFiles]
        });

        if (this.controlDefault && !this.controlDefault.value) {
          this.setDefaultAttachment(this.uploadedFiles[0]);
          this.selectedValue = this.uploadedFiles[0].filePath;
        }

      });
    }
    // TODO: Check remove files if fails
    event.files.splice(0, event.files.length);


  }

  deleteAttachment(file: IFileUpload) {
    const index: number = this.uploadedFiles.indexOf(file);
    if (index !== -1) {
      this.uploadedFiles.splice(index, 1);
    }
    this.value = JSON.stringify([...this.uploadedFiles]);
    this.onModelChange(this.value);
    this.onModelTouched();
    if (file.filePath === this.selectedValue) {
      this.setDefaultAttachment(this.uploadedFiles[0]);
      this.selectedValue = this.uploadedFiles[0].filePath;
    }
    this.OnChange.emit({
      originalEvent: event.AT_TARGET,
      value: [...this.uploadedFiles]
    });
  }

  downloadFile(file: IFileUpload) {
    this.service.fileDownload(file).subscribe((fileData) => {
      const blob = new Blob([fileData]);
      this.saveExportFile(blob, file.fileName);
    });
  }

  viewFile(file: any) {
    if (this.getImgUrl(file).includes('.pdf')) {
      const itemFiles = JSON.parse(file);
      const pdfUrl = this.env.apiUrl + 'ImportFiles/' + itemFiles[0].filePath;
      window.open(pdfUrl, '_blank');
    } else {
      this.imageUrl = this.env.apiUrl + 'ImportFiles/' + file.filePath;
      this.imageShowDialog = true;
    }
  }

  getImgUrl(file: IFileUpload) {
    return this.env.apiUrl + 'ImportFiles/' + file.filePath;
  }

  setDefaultAttachment(file: IFileUpload) {
    this.controlDefault.setValue(this.env.apiUrl + 'ImportFiles/' + file.filePath);
  }

  saveExportFile(blob: Blob, fileName: string) {
    const nav = (window.navigator as any);
    if (nav.msSaveOrOpenBlob) { // For IE:
      nav.msSaveBlob(blob, fileName);
    } else { // For other browsers:
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(link.href);
    }
  }

}

export interface IFileUpload {
  fileName: string;
  filePath: string;
  type: string;
}



