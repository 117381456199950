import { Component, OnInit, ElementRef, ViewChild, Renderer2, QueryList } from '@angular/core';
import { forkJoin, fromEvent, Subscription } from 'rxjs';
import { HelperService } from '../../shared/helperService';
import { LoginsService } from '../../core/service';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { DiscussesService } from '../../pages/adminCustom/discusses/discusses.service';
import { RaiseTicketsService } from '../../pages/adminCustom/raiseTickets/raiseTickets.service';
import { DiscussTypesService } from '../../pages/adminCustom/discussTypes/discussTypes.service';
import { AppUsersService } from '../../pages/adminCustom/appUsers/appUsers.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @ViewChild('li') li: ElementRef;
  @ViewChild('itemForm') itemForm: any;
  closeMenu: boolean = true;
  isMenuVisible: boolean = false;
  isOverlayVisible: boolean = false;
  isAdmin = false;
  sidebarClass = '';
  userInfo: any = null;
  isUserLoggedIn = false;
  loggedInUser: any;
  items: MenuItem[];
  menuList: any[];
  menuActive = true;
  currentRoute: string;
  isOpen = false;
  clickedElement: Subscription = new Subscription();
  menu: any[];
  filteredMenu = [];
  searchControl = new FormControl('');
  query: any;
  discussedPending: any[];
  raiseTicketListData: any[];
  discussTypesListData: any[];
  dealerApprovalListData: any[];
  passwordChangeDialog: boolean = false;
  passwordStatus: boolean = false;
  passwordEmpty: boolean = false;
  loading: boolean = false;
  private intervalId: any;
  constructor(
    private renderer: Renderer2,
    public loginsService: LoginsService,
    public helperService: HelperService,
    public router: Router,
    public discussesService: DiscussesService,
    public raiseTicketsService: RaiseTicketsService,
    public discussTypesService: DiscussTypesService,
    public appUsersService: AppUsersService,) {
    this.helperService.dataChange.subscribe((type) => {
      if (type == "changeData") {
        this.refreshData();
        this.setAutoRefresh();
      }
    });


    this.refreshData();
    this.setAutoRefresh();

    this.helperService.loginChange.subscribe((loginType) => {
      this.loggedInUser = this.helperService.getLoggedInUserInfo();
      this.isAdmin = this.helperService.isAdminUser;
      this.isUserLoggedIn = this.helperService.isUserLoggedIn;
    });
  }
  refreshData() {
    const discussesData = this.discussesService.getAll();
    const raiseTicketData = this.raiseTicketsService.getAll();
    const discussType = this.discussTypesService.getAll();
    const dealerApproval = this.appUsersService.getAll();
    forkJoin([discussesData, raiseTicketData, discussType, dealerApproval]).subscribe((results: any) => {
      this.discussedPending = results[0].filter((a) => !a.isApprove && !a.isDelete);;
      this.raiseTicketListData = results[1].filter(res => !('reply' in res));;
      this.discussTypesListData = results[2]?.filter((a) => a.answer == null);
      this.dealerApprovalListData = results[3]?.filter((a) => a.role == "Dealer" && a.publish == "Draft" && a.address);
      this.initializeMenu();
    })
  }
  setAutoRefresh() {
    this.intervalId = setInterval(() => {
      // window.location.reload();
      this.refreshData();
    }, 1 * 60 * 1000); // 2 minutes in milliseconds
  }
  initializeMenu() {
    this.menu = [
      {
        name: 'Dashboard',
        items: [
          { name: 'Home', route: 'admin/home', icon: 'ri-home-4-fill' }
        ]
      },
      {
        name: 'Users',
        items: [
          { name: 'Farmer', route: 'farmer/appUsers', icon: 'ri-user-fill' },
          { name: 'Dealer', route: 'dealer/appUsers', icon: 'ri-user-fill' },
          { name: 'Dealer Approval', route: 'appUsers/dealerApprove', icon: 'ri-user-fill', count: this.dealerApprovalListData?.length }
        ]
      },
      {
        name: 'Crop',
        items: [
          { name: 'Crop', route: 'crops', icon: 'ri-plant-fill' },
          // { name: 'Crop Hindi', route: 'crop_Hi', icon: 'ri-plant-fill' },
          // { name: 'Crop Marathi', route: 'crop_Mr', icon: 'ri-plant-fill' },
          { name: 'Crop Disease', route: 'cropDiseases', icon: 'ri-bug-2-fill' },
          // { name: 'Crop Disease Hindi', route: 'cropDisease_Hi', icon: 'ri-bug-2-fill' },
          // { name: 'Crop Disease Marathi', route: 'cropDisease_Mr', icon: 'ri-bug-2-fill' },
          { name: 'Deficiency Disorders', route: 'deficiencyDisorders', icon: 'ri-radar-fill' },
          // { name: 'Deficiency Disorders Hindi', route: 'deficiencyDisorder_Hi', icon: 'ri-radar-fill' },
          // { name: 'Deficiency Disorders Marathi', route: 'deficiencyDisorder_Mr', icon: 'ri-radar-fill' },
          { name: 'Plant Protections', route: 'plantProtections', icon: 'ri-plant-fill' },
          // { name: 'Plant Protections Hindi', route: 'plantProtection_Hi', icon: 'ri-plant-fill' },
          // { name: 'Plant Protections Marathi', route: 'plantProtection_Mr', icon: 'ri-plant-fill' },
        ]
      },
      {
        name: 'Comments',
        items: [
          { name: 'Krushi Charcha', route: 'discusses', icon: 'ri-chat-1-fill', count: this.discussedPending?.length },
          { name: 'Discuss Comments', route: 'discussComments', icon: 'ri-question-answer-fill' },
          { name: 'Plot Discussions', route: 'discussTypes', icon: 'ri-chat-search-fill', count: this.discussTypesListData?.length }
        ]
      },
      {
        name: 'Notification',
        items: [
          { name: 'All Notifications', route: 'allNotifications', icon: 'ri-notification-line' },
          { name: 'Push Notification', route: 'pushNotificationMessages', icon: 'ri-notification-2-fill' },
          { name: 'Push Notifiaction Mapper', route: 'pushNotificationMappers', icon: 'ri-notification-3-fill' }
        ]
      },
      {
        name: 'Products',
        items: [
          { name: 'Products', route: 'products', icon: 'ri-user-2-fill' },
          // { name: 'Products Hindi', route: 'product_Hi', icon: 'ri-user-2-fill' },
          // { name: 'Products Marathi', route: 'product_Mr', icon: 'ri-user-2-fill' },
          { name: 'Product Images', route: 'productImages', icon: 'ri-user-2-fill' },
          { name: 'Limited Time Sales', route: 'limitedTimeSales', icon: 'ri-discount-percent-fill' },
          // { name: 'Limited Time Sales Hindi', route: 'limitedTimeSale_Hi', icon: 'ri-discount-percent-fill' },
          // { name: 'Limited Time Sales Marathi', route: 'limitedTimeSale_Mr', icon: 'ri-discount-percent-fill' },
          { name: 'Coupon', route: 'coupons', icon: 'ri-coupon-fill' },
          { name: 'Krushi Packages', route: 'subscriptionPackages', icon: 'ri-box-2-fill' },
          // { name: 'Packages Hindi', route: 'subscriptionPackage_Hi', icon: 'ri-box-2-fill' },
          // { name: 'Packages Marathi', route: 'subscriptionPackage_Mr', icon: 'ri-box-2-fill' }
        ]
      },
      {
        name: 'Packages',
        items: [
          { name: 'Premium Packages', route: 'premiumPackages', icon: 'ri-map-pin-fill' },
          // { name: 'Premium Packages Hindi', route: 'premiumPackage_Hi', icon: 'ri-map-pin-fill' },
          // { name: 'Premium Packages Marathi', route: 'premiumPackage_Mr', icon: 'ri-map-pin-fill' },
          { name: 'Package Subscription History', route: 'packageSubscriptionHistories', icon: 'ri-box-1-fill' }
        ]
      },
      {
        name: 'Plot',
        items: [
          { name: 'Irrigation Plan', route: 'myPlots', icon: 'ri-map-2-fill' },
          { name: 'FAQ', route: 'plotFaqQueAns', icon: 'ri-questionnaire-fill' },
          { name: 'Farms', route: 'farms', icon: 'ri-pin-distance-fill' },
          { name: 'Plan Payments', route: 'plotPayments', icon: 'ri-money-rupee-circle-fill' },
          { name: 'Plot Calendar', route: 'myPloatCalanders', icon: 'ri-calendar-todo-fill' },
        ]
      },
      {
        name: 'Others',
        items: [
          { name: 'Gov. Schemes', route: 'notifications', icon: 'ri-notification-fill' },
          { name: 'Season Wises', route: 'seasonWises', icon: 'ri-notification-fill' },
          // { name: 'Notifications Hindi', route: 'notification_Hi', icon: 'ri-notification-fill' },
          // { name: 'Notifications Marathi', route: 'notification_Mr', icon: 'ri-notification-fill' },
          { name: 'All Documents', route: 'allDocumentUploads', icon: 'ri-file-text-fill' },
          // { name: 'All Notifications Hindi', route: 'allNotification_Hi', icon: 'ri-notification-fill' },
          // { name: 'All Notifications Marathi', route: 'allNotification_Mr', icon: 'ri-notification-fill' },
          { name: 'Banner', route: 'banners', icon: 'ri-image-fill' },
          { name: 'Buy Order', route: 'buyOrders', icon: 'ri-shopping-bag-4-fill' },
          { name: 'Category', route: 'categories', icon: 'ri-store-3-fill' },
          // { name: 'Category Hindi', route: 'category_Hi', icon: 'ri-store-3-fill' },
          // { name: 'Category Marathi', route: 'category_Mr', icon: 'ri-store-3-fill' },
          { name: 'Raise Tickets', route: 'raiseTickets', icon: 'ri-store-3-fill', count: this.raiseTicketListData?.length },
          // { name: 'Documents', route: 'documents', icon: 'ri-file-list-3-fill' },
          { name: 'Enum Details', route: 'enumDetails', icon: 'fa-sharp fa-solid fa-store' },
          // { name: 'FAQ', route: 'plotFaqQueAns', icon: 'ri-questionnaire-fill' },
          // { name: 'FAQ Hindi', route: 'plotFaqQueAns_Hi', icon: 'ri-questionnaire-fill' },
          // { name: 'FAQ Marathi', route: 'plotFaqQueAns_Mr', icon: 'ri-questionnaire-fill' },
          { name: 'Feedbacks', route: 'feedBacks', icon: 'ri-feedback-fill' },
          { name: 'Labs', route: 'labs', icon: 'ri-building-fill' },
          { name: 'Soil Report', route: 'soilReports', icon: 'ri-file-paper-2-line' },
        ]
      },
      {
        name: 'Masters',
        items: [
          { name: 'Pincode Masters', route: 'pincodeMasters', icon: 'ri-map-pin-fill' },
          { name: 'Wallet', route: 'wallets', icon: 'ri-wallet-fill' },
          { name: 'Change Password', icon: 'ri-lock-line' },
        ]
      },
      {
        items: [
        ]
      }
    ];
    this.filteredMenu = this.menu;
  }
  ngOnInit(): void {
    this.userInfo = this.helperService.getLoggedInUserInfo();
    this.searchControl.valueChanges.pipe(
      startWith(''),
      map(value => this.filterMenu(value))
    ).subscribe(filtered => {
      this.filteredMenu = filtered;
    });
  }

  toggleMenu() {
    this.isOpen = !this.isOpen;
  }
  goto(path: string) {
    this.helperService.orderTypeTab = '';
    this.helperService.clearSearchHistory();
    this.helperService.routeNavigate(path);
    this.helperService.setRouteData(null);
    this.isMenuVisible = false;
    this.isOverlayVisible = false;
    this.hidesideBar();
  }
  fun() {
    if (this.closeMenu) {
      this.renderer.addClass(this.li.nativeElement, 'showMenu');
      this.closeMenu = false;
    } else {
      this.renderer.removeClass(this.li.nativeElement, 'showMenu');
      this.closeMenu = true;
    }
  }

  logout() {
    this.loginsService.clearData();
    this.helperService.logout();
    this.helperService.routeNavigate("login");
  }

  onMenuButtonClick(event: any) {
    this.menuActive = !this.menuActive;
    event.preventDefault();
  }

  getActiveMenu(url: string): string {
    if (url.toLowerCase().indexOf("platters") >= 0) return "platters";
    if (url.toLowerCase().indexOf("investment-report") >= 0)
      return "investment-report";
    if (url.toLowerCase().indexOf("mandates") >= 0) return "mandates";
    if (url.toLowerCase().indexOf("bseRegistrations".toLowerCase()) >= 0)
      return "bseRegistrations";
    if (url.toLowerCase().indexOf("mfUserOrders".toLowerCase()) >= 0)
      return "mfUserOrders";
    if (
      url.toLowerCase().indexOf("edExpenses".toLowerCase()) >= 0 ||
      url.toLowerCase().indexOf("edIncomes".toLowerCase()) >= 0
    )
      return "tracker";
    if (url.toLowerCase().indexOf("contact-us") >= 0) return "contact-us";

    return "";
  }
  hidesideBar() {
    document.querySelector('.sidebar').classList.remove('visible');
    document.querySelector('.adminNav').classList.remove('shrink');
    document.querySelector('.blockLabel').classList.remove('visible');
  }

  filterMenu(searchTerm: string) {
    if (!searchTerm) {
      return this.menu;
    }
    return this.menu.map(category => {
      const filteredItems = category.items.filter(item =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      return { ...category, items: filteredItems };
    }).filter(category => category.items.length > 0);
  }
  selectedItem: any;
  newPassword: any;
  confirmPassword11: any;
  showSuccessDialog: boolean = false;
  passwordMatch() {
    this.loading = true;
    let condition = {};
    condition["Id"] = this.userInfo?.id;
    this.appUsersService.searchData(null, null, condition).subscribe((data) => {
      this.selectedItem = data;
      this.selectedItem[0].password = this.newPassword;
      this.selectedItem[0].id = this.userInfo?.id;

      if (this.newPassword?.length < 1 && this.confirmPassword11?.length < 1) {
        this.passwordEmpty = true;
        this.passwordStatus = false;
        this.loading = false;
      } else if (this.newPassword === this.confirmPassword11 && this.newPassword != undefined && this.confirmPassword11 != undefined) {
        this.passwordStatus = false;
        this.passwordEmpty = false;
        this.appUsersService
          .updatePassword11(this.selectedItem[0])
          .subscribe((data) => {
            this.loading = false;
            if (data == "Success") {
              this.passwordChangeDialog = false;
              this.showSuccessDialog = true;
              this.newPassword = '';
              this.confirmPassword11 = '';
              this.itemForm.reset();
            }
          });
      } else {
        this.passwordStatus = true;
        this.passwordEmpty = false;
        this.loading = false;
      }
    });
  }
}
