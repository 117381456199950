import { Component, OnInit } from '@angular/core';
import { HelperService } from '../../shared/helperService';
import { LoginsService } from '../../core/service';
import { AppUser } from '../../core/model';
import { AppUsersService } from '../../pages/custom/appUsers/appUsers.service';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  loginPopup: boolean = false;
  mobileNo: any;
  emailId = '';
  loading: boolean = false;
  errorMSG: string;
  mobileNumberPartial: string;
  steplogin: number = 0;
  resendOTP: boolean = false;
  timerCounterBase: number = 180;
  time: any;
  otpNum1 = '';
  otpNum2 = '';
  otpNum3 = '';
  otpNum4 = '';
  timerCounter;
  step = 0;
  otpFail = false;
  otp = '';
  emailVerify = false;
  mobileVerify = false;
  notRegisterDialog: boolean = false;
  otpVerify = false;
  model: any = {};
  successDialog: boolean = false;
  profilestep: number = 0;
  isShowError = false;
  emailToken = '';
  rememberme = false;
  authUserData: any;
  socialUser: any;
  firstName = '';
  lastName = '';
  fullName = '';
  isLoggedin: boolean;
  type = '';
  userInfo = null;
  isDeleteUser: boolean = false;
  isConfirmUserDelete: boolean = false;
  userDeleteSuccessful: boolean = false;
  constructor(
    public helperService: HelperService,
    private loginsService: LoginsService,
    private appUserService: AppUsersService,) {

    this.helperService.loginChange.subscribe((type) => {
      if (this.helperService.isUserLoggedIn) {
        this.userInfo = this.helperService.loggedInUser;

      } else {
        this.userInfo = null;
      }

    });
  }

  ngOnInit(): void {
    this.userInfo = this.helperService.loggedInUser;
  }
  sendOtpLogin() {
    this.loading = true;
    this.errorMSG = '';
    if (this.mobileNo?.toString().length == 10) {
      this.loginsService.sendLoginOtp(this.mobileNo).subscribe(msg => {
        if (msg === 'OTP sent') {
          this.mobileNumberPartial = this.mobileNo.toString().substr(0, 4) + "xxxxxx" + this.mobileNo.toString().substr(8, 2);
          this.steplogin = 1;
          this.resendOTP = true;
          if (this.timerCounterBase == 180) {
            clearTimeout(this.time)
          }

          this.otpNum1 = this.otpNum2 = this.otpNum3 = this.otpNum4 = "";
          this.timerCounter = this.timerCounterBase;
          this.loading = false;
          this.runTimer()
        }
      });
    }
  }

  runTimer() {
    this.time = setTimeout(() => {
      if (this.timerCounter >= 0) {
        this.timerCounter = this.timerCounter - 1;
        this.runTimer();
      }
    }, 1000);
  }

  checkOtpAndValidate() {
    if (this.otpNum1 != '' && this.otpNum2 != '' && this.otpNum3 != '' && this.otpNum4 != '') {
      this.validateOtpLogin();
    }
  }

  validateOtpLogin() {
    this.loading = true;
    this.otpFail = false;
    if (this.otpNum1 && this.otpNum2 && this.otpNum3 && this.otpNum4) {
      if (this.mobileNo.toString().length === 10) {
        const otp = this.otpNum1 + this.otpNum2 + this.otpNum3 + this.otpNum4;

        this.loginsService.validateOtp(this.mobileNo, otp).subscribe(msg => {
          this.loading = false;
          const msgVal = msg.split(':');
          this.otp = otp;
          if (msgVal[0] === 'success') {
            const isNotRegisterd = msgVal[1] === 'Not Register';
            if (isNotRegisterd) {
              this.emailVerify = false;
              this.mobileVerify = true;
              this.notRegisterDialog = true;
            } else {
              // call for Login
              this.otpVerify = true;
              this.emailVerify = false;
              this.model.mobile = this.mobileNo;
              this.login();

            }
          } else {
            this.otpFail = true;
          }
        });
      }
    }

  }



  login() {
    this.loginsService.loginMobile(this.model.mobile, this.otp).subscribe((data) => {
      this.loading = true;
      this.setLoginDetals(data)
      this.loading = false;
    });
  }

  setLoginDetals(data) {
    if (data) {
      this.loginsService.clearData();
      this.loginsService.helperSvc.setToken(data.token);
      this.helperService.setExpireToken(JSON.stringify(data.expireDate));
      this.helperService.setLoggedInUserInfo(JSON.stringify(data.userInfo));
      this.loading = false;
      const userInfo = data.userInfo[0] as AppUser;
      if (userInfo?.address == null && userInfo?.role == 'Dealer') {
        this.successDialog = false;
        const search = {};
        search["Id"] = userInfo?.id;
        this.appUserService.searchData(null, null, search).subscribe((data: AppUser[]) => {
          this.model = data[0];
          this.steplogin = 3;
          this.step = 3;
          this.profilestep = 0;
        });

      } else {
        this.loginsService.helperSvc.routeNavigate('home');
        this.loginPopup = false;
      }
    } else {
      this.isShowError = true;
      setTimeout(() => {
        this.isShowError = false;
      }, 5000);
    }
    this.step = 0;
    this.steplogin = 0;
    this.mobileNo = '';
    this.otpNum1 = this.otpNum2 = this.otpNum3 = this.otpNum4 = "";


  }


  onKeyUp(event, focusId) { // appending the updated value to the variable
    if (event.target.value == "") {
      const field = document.getElementById(focusId);
      if (field) {
        field.focus();
      }
    }
  }


  async googleAuth() {
    const user = await GoogleAuth.signIn() as any;
    this.authUserData = user;
    this.socialUser = user;
    this.firstName = user.givenName;
    this.lastName = user.familyName;
    this.fullName = this.firstName + ' ' + this.lastName;
    this.isLoggedin = (user != null);
    this.emailId = user.email;
    this.emailToken = user.idToken;
    this.emailVerify = true;
    this.type = 'Email';
    this.validateEmail();
  }

  async googleSignup() {
    // GoogleAuth.init();
    const googleUser = await GoogleAuth.signIn() as any;
    // this.userInfo = googleUser;
    this.emailId = this.model.emailId = googleUser.email;
    this.model.emailId = this.emailId;
    this.emailToken = googleUser.idToken;
    this.emailVerify = true;
    this.mobileVerify = false;
    this.validateEmail();
  }

  validateEmail() {
    this.loginsService.ValidateEmail(this.emailId, this.emailToken).subscribe(msg => {
      const msgVal = msg.split(':');
      if (msgVal[0] === 'success') {
        const isNotRegisterd = msgVal[1] === 'Not Register';
        if (isNotRegisterd) {
          this.emailVerify = false;
          this.mobileVerify = true;
          this.errorMSG = 'User Not Exists';
          this.notRegisterDialog = true;
          // this.step = 3;
          // this.instructionDialog=true;

        } else {
          // call for Login
          this.otpVerify = true;
          this.emailVerify = false;
          this.model.mobile = this.mobileNo;
          this.loginEmail();

        }
      }
    });

  }

  loginEmail() {
    this.loginsService.loginEmail(this.emailId, this.emailToken, this.rememberme).subscribe((data) => {
      this.loading = true;
      this.setLoginDetals(data)
    });
  }

  logout() {
    this.helperService.logout();
    // this.helperService.routeNavigate('login');
  }
  deleteUser() {
    this.appUserService.deleteUser().subscribe(msg => {
      if (msg === 'success') {
        this.userDeleteSuccessful = true;
        setTimeout(() => {
          this.userDeleteSuccessful = false;
          this.isDeleteUser = false;
          this.logout();
        }, 5000);
      }
    });
  }
  cancle() {
    this.isDeleteUser = false;
    this.isConfirmUserDelete = true;
    this.helperService.routeNavigate('home');

  }

  goto(path: string) {
    this.helperService.routeNavigate(path);
  }
}
