import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Discuss } from '../../../core/model';
import { DiscussesService as service } from '../../admin/discusses/discusses.service';


@Injectable({
    providedIn: 'root'
})

export class DiscussesService extends service {

}
