import {Pipe, PipeTransform} from '@angular/core';
@Pipe({
  name: 'splitImg'
})

export class SplitImgPipe implements PipeTransform {
  transform(value: any, data: any, type: 'image'): any {
    if (type == 'image') {
      if (data.image) {
        let image = data.image.split(', ');
        return image[0];;
      }
    }
  }
}
