import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { DiscussType } from '../../../core/model';
import { DiscussTypesService as service } from '../../admin/discussTypes/discussTypes.service';


@Injectable({
    providedIn: 'root'
})

export class DiscussTypesService extends service {
  getDiscussTypeDetail(condition: any) {
    const form = { form: null, condition: condition };
    return this.http.post(this.apiUrl + 'GetDiscussTypeDetail', form)
        .pipe(
            map(data => {
                return data;
            }),
            catchError(this.handleError)
        );
}
}
