import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment.prod';

@Component({
  selector: 'app-img-viewer',
  templateUrl: './img-viewer.component.html',
  styleUrls: ['./img-viewer.component.scss']
})
export class ImgViewerComponent implements OnInit {
  @Input() imageShowUrl: any;
  @Input() showImgPipe: boolean = true;
  scale: number = 1;
  scaleStep: number = 0.1;
  transformStyle: string = 'scale(1)';
  constructor() { }

  ngOnInit(): void {
  }

  onScroll(event: WheelEvent): void {
    event.preventDefault();
    if (event.deltaY < 0) {
      this.scale += this.scaleStep;
    } else {
      this.scale = Math.max(1, this.scale - this.scaleStep);
    }
    this.transformStyle = `scale(${this.scale})`;
  }
}
