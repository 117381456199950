import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { RaiseTicket } from '../../../core/model';
import { RaiseTicketsService as service } from '../../admin/raiseTickets/raiseTickets.service';


@Injectable({
    providedIn: 'root'
})

export class RaiseTicketsService extends service {
  
}
