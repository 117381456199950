import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CapitalizePipe } from './capitalize.pipe';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { ValidatorComponent } from './validator/validator.component';
import { TranslateModule } from '@ngx-translate/core';
import { TableModule } from 'primeng/table';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { GridRowSelectPipe } from './pipes/app.gridrow.select';
import { GridColumnShowPipe } from './pipes/app.gridcolumn.show';
import { SkeletonModule } from 'primeng/skeleton';
import { ToastModule } from 'primeng/toast';
import { OrderByPipe } from './pipes/app.orderby';
import { InrCurrencyPipe } from './pipes/app.inr-currency';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { FilterPipe } from './pipes/app.filter';
import { FileUploadViewComponent } from '../components/fileUploadView/fileUploadView.component';
import { PasswordModule } from 'primeng/password';
import { FooterComponent } from '../components/footer/footer.component';
import { BlockUIModule } from 'primeng/blockui';
import { BackButtonComponent } from '../components/back-btn/back-button.component';

import { SvgComponent } from '../components/svg/svg.component';
import { UploadImgPathPipe } from './pipes/app.upload-img-path';
import { ParsePipe } from './pipes/app.parse';
import { SafePipe } from './pipes/app.safe.pipe';
import { SipMinPipe } from './pipes/app.sip-min';
import { AutoTabDirective } from './Directive/auto-tab.directive';
import { ScrollIntoViewDirective } from './Directive/scroll-into-view.directive';
import { LoadingComponent } from '../components/loading/loading.component';

import { FooterTabsComponent } from '../components/footer-tabs/footer-tabs.component';
import { HeaderContentComponent } from '../components/header-content/header-content.component';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TooltipModule } from 'primeng/tooltip';
import { TabViewModule } from 'primeng/tabview';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CalendarModule } from 'primeng/calendar';
import { MenuModule } from 'primeng/menu';

import { InputTextModule } from 'primeng/inputtext';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { ListboxModule } from 'primeng/listbox';

import { ContextMenuModule } from 'primeng/contextmenu';
import { CheckboxModule } from 'primeng/checkbox';
import { FieldsetModule } from 'primeng/fieldset';
import { DialogModule } from 'primeng/dialog';
import { RatingModule } from 'primeng/rating';
import { StepsModule } from 'primeng/steps';
import { TreeModule } from 'primeng/tree';

import { SelectButtonModule } from 'primeng/selectbutton';
import { KeyFilterModule } from 'primeng/keyfilter';
import { SkeletonComponent } from '../components/skeleton/skeleton.component';

import { CardModule } from 'primeng/card';
import { DividerModule } from 'primeng/divider';

import { StyleClassModule } from 'primeng/styleclass';
import { BadgeModule } from 'primeng/badge';
import { RippleModule } from 'primeng/ripple';

import { InputSwitchModule } from 'primeng/inputswitch';
import { SortbyFilterComponent } from '../components/sortby-filter/sortby-filter.component';
import { UsersTabComponent } from '../components/users-tab/users-tab.component';
import { TrackerBtnComponent } from '../components/tracker-btn/tracker-btn.component';
import { HtmlContent } from './pipes/app.htmlcontent';
import { CarouselModule } from 'primeng/carousel';
import { UsersProfileComponent } from '../pages/auth/users-profile/users-profile.component';
import { AuthSystemComponent } from '../components/auth-system/auth-system.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { PaginatorModule } from 'primeng/paginator';
import { LoaderComponent } from '../components/loader/loader.component';
import { menuActivePipe } from './pipes/app.menuActive';
import { SearchPipe } from './pipes/app.search';
import { EditorModule } from 'primeng/editor';
import { ShowImgPipe } from './pipes/show-img.pipe';
import { ChartViewComponent } from '../components/chart-view/chart-view.component';
import { UtilitiesComponent } from '../components/utilities/utilities.component';
import { CanvasJSAngularChartsModule } from '@canvasjs/angular-charts';
import { StripHtmlPipe } from './pipes/app.stripHtml';
import { ImgViewerComponent } from '../components/img-viewer/img-viewer.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { SplitImgPipe } from './pipes/app.splitimg';

export function loadEcharts() {
  return import('echarts');
}

@NgModule({
  declarations: [CapitalizePipe, ValidatorComponent, FileUploadComponent, GridRowSelectPipe, GridColumnShowPipe, OrderByPipe, FilterPipe,
    InrCurrencyPipe,
    UploadImgPathPipe,
    FileUploadViewComponent, FooterComponent,
    BackButtonComponent,
    SvgComponent,
    ParsePipe, SipMinPipe, SafePipe, AutoTabDirective, ScrollIntoViewDirective, LoadingComponent,
    FooterTabsComponent, HeaderContentComponent, SkeletonComponent, SortbyFilterComponent,
    UsersTabComponent, TrackerBtnComponent, HtmlContent, UsersProfileComponent, AuthSystemComponent, LoaderComponent, menuActivePipe, SearchPipe,
    ShowImgPipe, ChartViewComponent, UtilitiesComponent, StripHtmlPipe, ImgViewerComponent,SplitImgPipe

  ],
  imports: [
    CommonModule, ReactiveFormsModule, FormsModule, FilterPipeModule, TranslateModule, RadioButtonModule, FormsModule, FileUploadModule,
    SelectButtonModule, ScrollPanelModule, DropdownModule,
    TableModule, PasswordModule, BlockUIModule, KeyFilterModule,
    TooltipModule,
    TabViewModule,
    StyleClassModule,
    InputTextModule,
    BadgeModule,
    RippleModule,
    InputSwitchModule,
    RadioButtonModule,
    ButtonModule,
    MenuModule,
    DialogModule,
    CardModule, DividerModule, CarouselModule, InputTextareaModule, PaginatorModule,
    NgxEchartsModule.forRoot({
      echarts: loadEcharts
    }),
    EditorModule,CanvasJSAngularChartsModule, ToastModule, GoogleMapsModule

  ],
  exports: [ReactiveFormsModule, FormsModule, CapitalizePipe, ValidatorComponent, TranslateModule, CommonModule,
    TableModule, DynamicDialogModule, FileUploadComponent, FileUploadModule,
    MenuModule, InputTextModule, ButtonModule, InputMaskModule,
    InputTextareaModule, CalendarModule, RadioButtonModule, FieldsetModule, DropdownModule,
    MultiSelectModule, ListboxModule, RatingModule,
    ContextMenuModule, TabViewModule, DialogModule, StepsModule, TreeModule,SplitImgPipe,
    TooltipModule, ConfirmDialogModule,
    GridRowSelectPipe, OrderByPipe,
    FilterPipe, UploadImgPathPipe,
    InrCurrencyPipe,
    CheckboxModule,
    GridRowSelectPipe, GridColumnShowPipe,
    CheckboxModule,
    CheckboxModule,
    ScrollPanelModule, SelectButtonModule, ToastModule,
    FileUploadViewComponent, PasswordModule, FooterComponent,
    BlockUIModule, BackButtonComponent,
    SvgComponent,
    ParsePipe, SipMinPipe, SafePipe,
    KeyFilterModule, AutoTabDirective, ScrollIntoViewDirective, LoadingComponent,
    FooterTabsComponent, HeaderContentComponent, SkeletonComponent, SkeletonModule, CardModule, DividerModule,
    TooltipModule,
    TabViewModule,
    StyleClassModule,
    InputTextModule,
    BadgeModule,
    RippleModule,
    InputSwitchModule,
    RadioButtonModule,
    ButtonModule,
    MenuModule, SearchPipe,
    SortbyFilterComponent, UsersTabComponent, TrackerBtnComponent, HtmlContent, CarouselModule, AuthSystemComponent, NgxEchartsModule, PaginatorModule, LoaderComponent, menuActivePipe,
    EditorModule, ShowImgPipe, ChartViewComponent, UtilitiesComponent,CanvasJSAngularChartsModule, StripHtmlPipe,
    ImgViewerComponent, GoogleMapsModule

  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  entryComponents: []
})
export class SharedModule { }
